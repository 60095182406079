import {
  generateChapterItem,
} from '@/views/material/index/build/components/tool-fns.js';

export function processCourseResData(resDataList) {
  const tempChapters = [];
  if (Array.isArray(resDataList)) {
    resDataList.forEach(item => {
      if (Number(item.has) === 1) { // 教材
        item.chapter_list.forEach(({ title, section_list }) => {
          tempChapters.push({
            title,
            section_list: section_list.map(sectionData => ({
              ...sectionData,
              readOnly: true
            })),
            readOnly: true
          });
        });
      } else {
        const {
          title,
          section_list
        } = item;
        tempChapters.push({
          title,
          section_list
        });
      }
    });
  }
  return tempChapters;
}
export function processChapterList(chapterList) {
  if (Array.isArray(chapterList)) {
    return chapterList.map((chapterItem, chapterIndex) => generateChapterItem(chapterItem, chapterIndex));
  } else {
    return [
      generateChapterItem()
    ];
  }
}