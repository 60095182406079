export const composePaperWayEnum = {
  FROM_EXERCISES: 1,
  1: 'FROM_EXERCISES',
  MANUAL: 2,
  2: 'MANUAL'
};

export const paperComposeTypeBriefName = {
  1: '习题库组卷',
  2: '手动组卷'
};

export default {
  1: '从习题库中组卷',
  2: '手动组卷'
};