<template>
  <div>
    <NCard
      title="考试设置"
      size="small"
      :segmented="{ content: 'hard' }"
    >
      <div class="exam-settings">
        <div
          v-for="(item, index) in exams"
          :key="index"
          class="card-wrap"
        >
          <div class="exam-card">
            <div class="card-left">
              <div>考试标题： </div>
              <div>试卷： </div>
            </div>
            <div class="card-center">
              <div>
                <NInput
                  size="small"
                  placeholder="请输入标题"
                  maxlength="20"
                  show-count
                  clearable
                  v-model:value="item.title"
                />              
              </div>
              <div class="paper-title">{{ item.p_title }}</div>
            </div>
            <div class="card-right">
              <div>
                <NButton text @click="handleClickDel(item)">
                  <template #icon>
                    <NIcon><CloseOutlined /></NIcon>
                  </template>
                </NButton>
              </div>
              <div>
                <NButton text type="primary" @click="handleClickChangePaper(item)">更换试卷</NButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <NButton size="small" type="primary" @click="handleClickAdd">从试卷库中添加考试</NButton>
      </div>
    </NCard>

    <AddPaperModal
      ref="addPaperModal"
      @paper-id-add="handlePaperIdAdd"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { CloseOutlined } from '@vicons/material';

  import AddPaperModal from '@/components/AddPaperModal/AddPaperModal.vue';

  const addPaperModal = ref(null);
  const exams = ref([]);
  let changePaperExam = null;

  const handleClickDel = item => {
    exams.value.splice(exams.value.indexOf(item), 1);
  };
  const handleClickChangePaper = item => {
    changePaperExam = item;
    const filterIds = exams.value.map(({ paper_id }) => paper_id);
    filterIds.splice(filterIds.indexOf(item.paper_id), 1);
    addPaperModal.value.openModal({
      initIds: [item.paper_id],
      filterIds
    });
  };
  const handleClickAdd = () => {
    changePaperExam = null;
    addPaperModal.value.openModal({
      filterIds: exams.value.map(({ paper_id }) => paper_id)
    });
  };
  const handlePaperIdAdd = ([{ id: paper_id, title: p_title } = {}]) => {
    if (!changePaperExam) {
      exams.value.push({
        title: '',
        paper_id,
        p_title
      });
    } else {
      Object.assign(changePaperExam, {
        paper_id,
        p_title
      });
    }
  };

  const initData = (resExams) => {
    if (Array.isArray(resExams)) {
      exams.value = resExams.map(({ title, paper_id, p_title }) => ({
        title,
        paper_id,
        p_title
      }));
    }
  };

  defineExpose({
    initData,
    validate: () => {
      return new Promise(resolve => {
        if (exams.value.length > 0) {
          if (exams.value.some(({ title }) => !title.trim())) {
            resolve('请输入考试标题');
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    getData: () => exams.value
  });
</script>

<style lang="less" scoped>
  .exam-settings {
    margin-right: -16px;
    font-size: 0;
  }

  .card-wrap {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding-right: 16px;
    margin-bottom: 12px;
    box-sizing: border-box;
  }
  .exam-card {
    position: relative;
    line-height: 36px;
    padding: 8px 15px;
    background-color: #f5f5f5;
    font-size: 14px;
  }
  .card-left {
    position: absolute;
    text-align: right;
    color: #999;
  }
  .card-center {
    margin: 0 70px 0 75px;
  }
  .paper-title {
    min-height: 36px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
  .card-right {
    position: absolute;
    top: 8px;
    right: 10px;
    text-align: right;
  }
</style>