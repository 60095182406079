<template>
  <div id="course-build-main">
  <NSteps :current="currentStep" :status="currentStatus" style="margin: 30px 0 30px;">
    <NStep v-if="!fixContent" title="课程架构" /> 
    <!-- <NStep title="课程架构" /> -->
    <NStep title="学习设置" />
  </NSteps>
  <Step1
    v-if="!fixContent"
    v-show="currentStep === maxStep - 2"
    ref="step1Ref"
    @id-label-update="idLabel = $event"
    @id-jlabel-update="JidLabel = $event"
    @id-klabel-update="KidLabel = $event"
    @id-slabel-update="SidLabel = $event"
    @step-next="nextStep"
    @modal-close="closeModal"
    v-bind="$attrs"
  />
  <Step2
    ref="step2Ref"
    v-show="currentStep === maxStep - 1"
    @step-pre="preStep"
    @modal-close="closeModal"
    :id-label="idLabel"
    :id-jlabel="JidLabel"
    :id-klabel="KidLabel"
    :id-slabel="SidLabel"
    :id-school="idSchool"
    :TrainingBook="TrainingBook"
    v-bind="$attrs"
  />
  </div>
</template>

<script setup>
  import { ref, defineExpose, computed, nextTick, defineEmits } from 'vue';

  import Step1 from './components/Step1.vue';
  import Step2 from './components/Step2.vue';

  const emit = defineEmits(["list-update"]);

  const props = defineProps({
    distributeTitle: {
      type: String,
      default: ''
    },
    TrainingBook:Array,
    fixContent: {
      type: Boolean,
      default: false
    }
  });

  const maxStep = computed(() => {
    return props.fixContent ? 2 : 3;
  });

  const step1Ref = ref(null);
  const step2Ref = ref(null);

  const modalShow = ref(true);
  const openModal = () => {
    modalShow.value = true;

  const TrainingBook = props.TrainingBook

    if (props.fixContent) {
      nextTick(() => {
        step2Ref.value.initTableData();
      });
    } else {
      step1Ref.value && step1Ref.value.handleClear();
    }
  };

  const closeModal = () => {
    modalShow.value = false;
  };

  const currentStep = ref(1);
  const currentStatus = ref('process');
  const nextStep = () => {
    const tempMaxStep = maxStep.value;
    if (currentStep.value < tempMaxStep) {
      currentStep.value += 1;
      step2Ref.value.initData();
    }
    // if (tempMaxStep > 2 && currentStep.value === 2) {
    //   step2Ref.value.initTableData();
    // }
  };
  const preStep = () => {
    if (currentStep.value > 1) {
      currentStep.value -= 1;
    }
  };

  const idLabel = ref({});
  const JidLabel = ref({});
  const KidLabel = ref({});
  const SidLabel = ref({});
  
  const idSchool = ref({});

  defineExpose({
    openModal
  });
</script>
<style scoped>
#course-build-main{
  background: #fff;
  padding: 20px;
}
.n-step-content-header__title{
  font-weight: 700;
}
.n-card-header__main{
  font-weight: 700 !important;
}
</style>