import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

 
// 课程管理 列表
export function GetCourseIndex(data) {
  return request({
    url: '/backend/web/course/index',
    method: GET,
    params:data
  });
}

// 课程状态改变
export function PostCourseState(data) {
  return request({
    url: '/backend/web/course/state',
    method: POST,
    data
  });
}

// 课程架构详情
export function GetCourseDetail(data) {
  return request({
    url: '/backend/web/course/detail',
    method: GET,
    params:data
  });
}

// 课程删除
export function PostCourseDelete(data) {
  return request({
    url: '/backend/web/course/delete',
    method: POST,
    data
  });
}

// 课程新增
export function PostCourseCreate(data) {
  return request({
    url: '/backend/web/course/create',
    method: POST,
    data
  });
}

// 实训分发
export function PostCourseDistribute(data) {
  return request({
    url: '/backend/web/course/distribute',
    method: POST,
    data
  });
}

// 教学系统列表
export function GetTeachingSystem(data) {
  return request({
    url: '/backend/web/task/teaching-system',
    method: GET,
    params:data
  });
}

// 课程建设 (新增架构、编辑架构、编辑资源)
export function PostCourseUpdate(data) {
  return request({
    url: '/backend/web/course/update',
    method: POST,
    data
  });
}

// 教材列表
export function getMaterialList(data) {
  return request({
    url: '/backend/web/material/index',
    method: GET,
    params: data
  });
}

// 教材列表确定事件
export function PostGetMaterialSource(data) {
  return request({
    url: 'backend/web/course/get-material-source',
    method: POST,
    data
  });
}
