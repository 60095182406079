<template>
  <div>
    <NGrid :x-gap="15" :cols="24">
      <NGi :span="8">
        <MaterialDir
          ref="materialDirRef"
          read-only
          :dir-data="chapters"
          @editing-section-change="editingSection = $event"
        />
      </NGi>
      <NGi :span="16">
        <MaterialSection
          :editing-section="editingSection"
        />
      </NGi>
    </NGrid>
    <ExamSettings
      ref="examSettingsRef"
      style="margin-top: 15px;"
    />
    <CourseFile
      ref="courseFileRef"
      style="margin-top: 15px;"
    />
    <NSpace size="large" style="margin-top: 10px;">
      <NButton size="large" style="width: 120px;" @click="router.push('index');">返回</NButton>
      <NSpace>
        <NButton size="large" style="width: 120px;" @click="emit('step-pre');">上一步</NButton>
        <NButton size="large" type="primary" style="width: 120px;" @click="handleClickDone">完成</NButton>
      </NSpace>
    </NSpace>

    <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"
      @all-file-success="submitData"
      @upload-error="handleUploadError"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import MaterialDir from '@/views/material/index/build/components/MaterialDir.vue';
  import MaterialSection from '@/views/material/index/build/components/MaterialSection.vue';
  import ExamSettings from './ExamSettings.vue';
  import CourseFile from './CourseFile.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';

  import {
    GetCourseDetail,
    PostCourseUpdate
  } from '@/api/course.js';

  import {
    getChapters
  } from '@/views/material/index/build/components/tool-fns.js';
  import {
    processCourseResData,
    processChapterList
  } from './process-course-res-data.js';

  import { resStatusEnum } from '@/enumerators/http.js';

  const emit = defineEmits(['step-pre']);

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const router = useRouter();
  const route = useRoute();
  const message = useMessage();

  const courseId = route.query.id;

  const materialDirRef = ref(null);
  const examSettingsRef = ref(null);
  const courseFileRef = ref(null);
  const beforeSubmitUploadRef = ref(null);
  const chapters = ref([]);
  const editingSection = ref({});

  
  
  const initData = () => {
    loading.value = true;
    GetCourseDetail({
      'Course[id]': courseId,
      'Course[type]': 2
    }).then(res => {
      if (res.code === SUCCESS) {
        chapters.value = processChapterList(processCourseResData(res.data.list));
        nextTick(() => {
          materialDirRef.value.expandAllChapters();
        });
        examSettingsRef.value.initData(res.data.exam_list);
        courseFileRef.value.initData(res.data.upload_info);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  const handleClickDone = () => {
    examSettingsRef.value.validate().then(errMsg => {
      if (errMsg) {
        message.error(errMsg);
      } else {
        courseFileRef.value.validate().then(errMsg => {
          beforeSubmitUploadRef.value.startUpload([
            courseFileRef.value.getFiles()
          ]);
        });
      }
    });
  };

  const submitData = fileList => {
    loading.value = true;
    const reqData = {
      'Course[id]': courseId,
      'Course[type]': 1,
      'Course[list]': getChapters(chapters.value, 'course'),
      'Course[exam]': examSettingsRef.value.getData(),
    };
    if (fileList[0] && fileList[0][0]) {
      const {
        fileUrl: file_url,
        name: file_name,
        size: file_size,
        ext: extension,
        fileType: file_type,
        videoId: video_id
      } = fileList[0][0];
      reqData['Course[upload]'] = {
        file_url,
        file_name,
        file_size,
        extension,
        file_type,
        video_id
      };
    }
    PostCourseUpdate(reqData).then(res => {
      if (res.code === SUCCESS) {
        message.success('保存成功');
        router.push('index');
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  const handleUploadError = () => {
    console.log('有文件上传失败，返回');
  };

  defineExpose({
    initData
  });
</script>

<style lang="less" scoped>

</style>