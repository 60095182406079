<template>
  <div style="margin-bottom: -8px;">
    <NUpload
      :default-upload="false"
      @change="handleChange"
      show-download-button
      :max="maxNum"
      multiple
      :accept="accept"
      @before-upload="beforeUpload"
      @remove="DeleteEvent"
      v-model:file-list="fileListStore"
    >
      <NUploadDragger
        style="width: 300px;"
      >
        <div>
          <NIcon size="38" color="#ccc">
            <CloudUploadOutline />
          </NIcon>
        </div>
        <div style="font-size: 13px; color: #666;">
          <div style="margin: 2px 0 10px;">将文件拖到此处，或点击上传</div>
          <div style="color: #999;">
            <div>只能上传</div>
            <div>
              <span>{{ accept.split(',').join('、') }} 文件</span>
            </div>
            <div>{{ acceptSizeTxt }}</div>
          </div>
        </div>
      </NUploadDragger>
    </NUpload>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { CloudUploadOutline } from '@vicons/ionicons5';
  import { useMessage } from 'naive-ui';

  import { getFileTypeFromExt } from '@/enumerators/file-type.js';
  import { getExtWithDotFromFileName, getFileUniqueStr } from '@/utils/utils.js';

  const message = useMessage();

  const emit = defineEmits(['file-list-change']);
  const props = defineProps({
    maxNum: {
      default: undefined
    },
    accept: {
      type: String
    },
    maxBytesSize: {
      type: Number,
      default: 0
    },
    acceptSizeTxt: {
      type: String,
      default: ''
    },
    initList: {
      type: Array,
      default: () => []
    },
    DeleteParameter :{
      type: Array
    },
  });

  const fileListStore = ref([]);

  watch(
    () => props.initList,
    list => {
      fileListStore.value = list;
    },
    { immediate: true }
  );

  const handleChange = ({ fileList }) => {
    fileListStore.value = fileList;
    emit('file-list-change');
  };
  
  const getFileMaxSize = extWithDot => {
    if (props.maxBytesSize > 0) {
      return props.maxBytesSize;
    } else {
      switch (extWithDot) {
        case '.pdf':
          return 1024 * 1024 * 50;
        case '.mp4':
        case '.mp3':
          return 1024 * 1024 * 500;
        case '.jpg':
        case '.jpeg':
        case '.png':
        case '.gif':
          return 1024 * 1024 * 5;
      }
    }
  };
  const beforeUpload = ({ file }) => {
    const {
      name,
      size
    } = file.file;
    const extWithDot = getExtWithDotFromFileName(name);
    if (!props.accept.split(',').includes(extWithDot)) {
      message.error(`请选择 ${ props.accept.split(',').join('、') } 格式文件`);
      return false;
    }
    const fileMaxSize = getFileMaxSize(extWithDot);
    if (size > fileMaxSize) {
      message.error(`文件超出限制大小 ${ fileMaxSize / 1024 / 1024 }MB`);
      return false;
    }
    return true;
  }  

  const getFileList = () => {
    return fileListStore.value.map(elem => {
      const {
        savedId,
        name,
        file,
        saved,
        fileUrl
      } = elem;
      const extWithDot = getExtWithDotFromFileName(name);
  
      return {
        savedId,
        name,
        size: file.size, // 字节
        ext: extWithDot,
        file: file,
        fileType: getFileTypeFromExt(extWithDot),
        upComplete: false,
        saved: !!saved,
        videoId: '',
        fileUniqueStr: getFileUniqueStr(file),
        upPercentage: 0,
        fileUrl: fileUrl || ''
      }
    });
  };

  const DeleteParameter = []

  const DeleteEvent = (e)=>{
    DeleteParameter.push(e.file)
    return DeleteParameter
  }
  defineExpose({
    getFileList,
    DeleteParameter
  });
</script>