<template>
  <NModal
    v-model:show="modalShow"
    preset="card"
    size="small"
    title="试卷库"
    :segmented="{ content: 'hard', footer: 'hard' }"
    style="width: 1200px;"
    :mask-closable="false"
  >
    <template #default>
      <div>
        <PaperListWithSearch
          ref="paperListRef"
          single-select
        />
      </div>
    </template>
    <template #footer>
      <NSpace justify="end">
        <NButton @click="closeModal">取消</NButton>
        <NButton type="primary" @click="confirmModal">确定</NButton>
      </NSpace>
    </template>
  </NModal>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { useMessage } from 'naive-ui';

  import PaperListWithSearch from '@/components/PaperListWithSearch/PaperListWithSearch.vue';

  const emit = defineEmits(['paper-id-add']);

  const message = useMessage();
  const paperListRef = ref(null);

  const modalShow = ref(false);
  const openModal = (args) => {
    modalShow.value = true;
    nextTick(() => {
      paperListRef.value.initData(args);
    });
  };
  const closeModal = () => {
    modalShow.value = false;
  };
  const confirmModal = () => {
    const papers = paperListRef.value.getSelectedPapers();
    if (papers.length === 0) {
      message.error('请选择试卷');
    } else {
      emit('paper-id-add', papers);
      closeModal();
    }
  };

  defineExpose({
    openModal
  });
</script>