import request from '@/utils/request.js';
import { formDataUploadFile } from '@/utils/formdata-upload-file.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function getPaperList(data) {
  return request({
    url: '/backend/web/paper/index',
    method: GET,
    params: data
  });
}

export function batDelPaper(data) {
  return request({
    url: '/backend/web/paper/delete',
    method: POST,
    data
  });
}

export function distributePaper(data) {
  return request({
    url: '/backend/web/paper/distribute',
    method: POST,
    data
  });
}

export function getExercises(data) {
  return request({
    url: '/backend/web/paper/exercises',
    method: GET,
    params: data
  });
}

export function createPaperFromExercises(data) {
  return request({
    url: '/backend/web/paper/create',
    method: POST,
    data
  });
}

export function getPaperDetail(data) {
  return request({
    url: '/backend/web/paper/detail',
    method: POST,
    data
  });
}

export function editPaperFromExercises(data) {
  return request({
    url: '/backend/web/paper/update',
    method: POST,
    data
  });
}

export function batImportExercisesForData (data) {
  return formDataUploadFile(
    '/backend/web/paper/uploads',
    data
  );
}