<template>
  <n-upload
    id="pictureUpload"
    action=""
    v-model:file-list="fileList"
    @remove="handleRemove"
    @before-upload="upbefore"
    @handleDrop="handleDrop"
    @change="handleDrop"
    :default-upload="false"
    :abstract="false"
    accept=".png, .jpg"
    :headers="Authorization"
    list-type="image-card"
  >
  <p class="title-up-text">{{text}}</p>
  </n-upload>
  <PageLoading :loading="loading" />
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { getToken } from "@/utils/auth.js";
import { ArchiveOutline as ArchiveIcon } from "@vicons/ionicons5";
import axios from "axios";
import { resStatusEnum } from '@/enumerators/http.js';
import env from '@/settings/env.js';
import { uploadIndex } from '@/api/upload.js';
import PageLoading from '@/components/PageLoading/index.vue';
const { SUCCESS } = resStatusEnum;
const { apiUrlPrefix } = env;
import { toRaw } from '@vue/reactivity'
const loading = ref(false);

export default defineComponent({
  components: {
    ArchiveIcon,
    PageLoading
  },
  // 父组件传来的值
  props: {
    text: String,
    size: String,
    removeUrl: String
  },
  setup(props, context) {
    const { attrs, slots, emit } = context;
    const message = useMessage();
    const OSS = require("ali-oss");
    var imagedata = [];
    var size = Number({ ...props }.size); // 文件大小限制
    var removeUrl = { ...props }.removeUrl; // 删除的接口信息
    const fileListRef = ref(attrs.imaData); // 原头像地址
    var text = Number({ ...props }.text); // 文件大小限制
    const isTrue = ref(true)
    // console.log("size:", size);
    return {
      token: getToken(),
      // 上传所需Authorization
      Authorization: {
        Authorization: getToken(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      fileList: fileListRef, // 文件列表
      apiUrlPrefix,
      imagedata:toRaw(imagedata),
      // 上传之前
      upbefore(file) {
        // message.info("上传之前");
        var fileData = file.file.file;
        var isJPEG = fileData.name.split(".")[1] === "jpeg";
        var isJPG = fileData.name.split(".")[1] === "jpg";
        var isPNG = fileData.name.split(".")[1] === "png";
        var isPDF = fileData.name.split(".")[1] === "pdf";
        var format = fileData.name.split(".")[1];

        var isLt20MK = fileData.size / 1024 / 1024;
        if (!isJPG && !isJPEG && !isPNG && !isPDF) {
          message.info("上传文件只能是 JPEG/JPG/PNG/PDF 格式!");
          isTrue.value = false
        }
        if (isLt20MK > size) {
          message.info("单张文件大小不能超过" + size + "M !");
          isTrue.value = false
        }
        if((isJPG || isJPEG || isPNG || isPDF) && isLt20MK <= size){
          isTrue.value = true
        }
        // console.log("file", file);
        if(isTrue.value==true){
          loading.value = true
          axios
          .get(apiUrlPrefix+"/backend/web/upload/index", {
            id: 1,
            headers: {
              Authorization: getToken(),
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (res) {
            if (res.status == 200) {
              // console.log("res:", res.data.data);
              var fileName =
                res.data.data.folder +
                "/yunpingtai" +
                `${Date.parse(new Date())}` +
                "." + 
                format; //定义唯一的文件名
              // console.log("client", client);
              let client = new OSS({
                region: res.data.data.region,
                accessKeyId: res.data.data.accessKeyId,
                accessKeySecret: res.data.data.accessKeySecret,
                bucket: res.data.data.bucket,
                stsToken: res.data.data.securityToken,
              });

              client
                .multipartUpload(fileName, fileData)
                .then(function (result) {
                  let fileUrl = result.res.requestUrls[0];
                  let index= fileUrl.lastIndexOf("?");
                  // if(index){
                  //   if(index>0){
                  //     fileUrl = fileUrl.substring(0,index)
                  //   }
                  // }else{
                  //   fileUrl = result.res.requestUrls[0]
                  // }
                  let srt1 = 'http://'
                  let srt2 = res.data.data.bucket +'.'
                  let srt3 = res.data.data.region+'.aliyuncs.com/'
                  let srt4 = srt1+srt2+srt3+fileName
                  imagedata = [{
                    name: fileName,
                    url: srt4,
                    reallyname: fileData.name,
                  }];
                  // 传值给父组件
                  loading.value = false
                  context.emit("imagedata", imagedata);
                  console.log("传值给父组件",imagedata)
                })
                .catch(function (err) {
                  message.info(err);
                });
            }
          })
          .catch(function (err) {
            message.info(err);
          });
        }else{
          imagedata = []
          context.emit("imagedata",imagedata);
          // message.info("上传的文件不符合要求");
          return false
        }
      },

      // 文件上传
      handleDrop(file) {
        // console.log("文件数据改变了", file);
      },

      // 文件删除
      handleRemove(e) {
        // 删除后把新的数据传给父组件
        let data = new FormData();
        data.append('image',e.file.url);   
        console.log("文件删除",e)     
        // 旧数据删除
        axios
          .post(apiUrlPrefix+removeUrl,
            data,{headers: {
              Authorization: getToken(),
              "Content-Type": "application/x-www-form-urlencoded",
            }})
          .then(function (res) {
            if (res.data.code == 1) {
              message.info("文件删除成功!");
              imagedata = []
              context.emit("imagedata",imagedata);
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    };
  },
});
</script>
<style lang="less">
#UploadMap{
    max-width: 360px;
}

.alone-possess .n-progress-graph-line-rail{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.alone-possess .n-progress-graph-line-fill{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.alone-possess .n-upload-trigger{
    width: 100%;
}

.alone-possess #UploadMap .n-icon.n-icon--depth.n-icon--color-transition{
    background: url(../../../public/img/upload/9789225d.png);
}

.c999{
    color: #999;
    font-size: 14px;
}

.alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
    width: 100px;
    height:100px;
    position: relative;
    background: url(../../../public/img/upload/C6173F69.png) no-repeat;
    background-size: 100%;
}

.alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card:first-child{
    display: block;
}
.alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card{
    display: none;
}
.alone-possess .title-up-text{
    font-size: 14px;
    color: #999;
    width: 200px;
    position: absolute;
    left:120px;
}
</style>