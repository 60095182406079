<template>
  <NGrid :x-gap="20" :col="24">
    <NGi :span="6">
      <div :style="{ border: isPage ? 'none' : '1px solid #f2f2f2' }">
        <LabelList
          ref="labelListRef"
          label-list-title="标签列表"
          :label-type="resourceEnum.PAPER_RESOURCE_TYPE"
          show-no-label
          @list-update="handlePageChange(1)"
        />
      </div>
    </NGi>
    <NGi :span="18">
      <div :style="{ border: isPage ? 'none' : '1px solid #f2f2f2' }">
        <NCard size="medium" :bordered="false">
          <SearchForm ref="searchFormRef" @list-update="handlePageChange(1)" />
          <div v-if="isPage" style="margin-bottom: 15px;">
            <NSpace justify="space-between">
              <NSpace>
                <NButton
                  v-if="ownUrlPermission('paper/delete')"
                  :disabled="selectedIds.length === 0"
                  @click="toDel('multiple')"
                >批量删除</NButton>
                <NButton
                  v-if="ownUrlPermission('paper/export')"
                  tag="a"
                  :href="batExportUrl"
                  :target="batExportUrl === 'javascript:;' ? '_self' : '_blank'"
                  :disabled="selectedIds.length === 0"
                >批量导出</NButton>
              </NSpace>
              <NSpace>
                <NButton v-if="ownUrlPermission('paper/distribute')" @click="toDistribute">分发</NButton>
                <NDropdown
                  v-if="ownUrlPermission('paper/create')"
                  trigger="click"
                  :options="addBtnOptions"
                  @select="handleAdd"
                >
                  <NButton type="primary" icon-placement="right">
                    <template #default>新增试卷</template>
                    <template #icon>
                      <NIcon color="#fff" size="18">
                        <ChevronDown />
                      </NIcon>
                    </template>
                  </NButton>
                </NDropdown>
              </NSpace>
            </NSpace>
          </div>
          <NAlert
            v-show="selectedIds.length > 0"
            style="margin-bottom: 10px;"
            type="info"
          >
            已选择 <span :style="{ color: themeSettings.appThemeColor }">{{ selectedIds.length }}</span> 项数据
          </NAlert>
          <NDataTable
            :columns="columns"
            :data="tableData"
            :row-key="row => row.id"
            remote
            :pagination="pagination"
            @update:page="handlePageChange"
            @update:page-size="handlePageSizeChange"
            :checked-row-keys="selectedIds"
            @update:checked-row-keys="handleSelect"
          />
        </NCard>
      </div>
    </NGi>
  </NGrid>

  <Distribute
    ref="distributeRef"
    distribute-title="分发试卷"
    :label-type="resourceEnum.PAPER_RESOURCE_TYPE"
    :distribute-fn="distributePaper"
  />
  <PageLoading :loading="loading" />
</template>

<script setup>
  import { h, ref, reactive, onMounted, computed } from 'vue';
  import { NSpace, NButton, NTime, useMessage, useDialog } from 'naive-ui';
  import { useRouter } from 'vue-router';
  import { ChevronDown } from '@vicons/ionicons5';

  import PageLoading from '@/components/PageLoading/index.vue';
  import LabelList from '@/components/LabelList/LabelList.vue';
  import SearchForm from './components/SearchForm.vue';
  import Distribute from '@/components/Distribute/Distribute.vue';

  import { getPaperList, batDelPaper, distributePaper } from '@/api/paper.js';
  import themeSettings from '@/settings/theme-settings.js'; 
  import env from '@/settings/env.js';
  import { getToken } from '@/utils/auth.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import composePaperWayMap, { composePaperWayEnum, paperComposeTypeBriefName } from '@/enumerators/compose-paper-way-map.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  const props = defineProps({
    isPage: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: false
    }
  });

  const { SUCCESS } = resStatusEnum;

  const router = useRouter();
  const dialog = useDialog();
  const message = useMessage();
  const loading = ref(false);
  const labelListRef = ref(null);
  const searchFormRef = ref(null);

  const idPaperMap = {};
  const updateIdPaperMap = resPaperData => {
    if (Array.isArray(resPaperData)) {
      resPaperData.forEach(({ id, title }) => {
        idPaperMap[id] = {
          id,
          title
        }
      });
    }
  };
  const selectedIds = ref([]);
  const columns = [
    { type: 'selection' },
    { title: '试卷 ID', key: 'id' },
    { title: '标题', key: 'title' },
    { title: '创建者', key: 'admin_username' },
    { title: '题数（道）', key: 'exercises_num' },
    { title: '总分值（分）', key: 'score' },
    {
      title: '组卷方式',
      render: row => h(
        'span',
        null,
        paperComposeTypeBriefName[row.type]
      )
    },
    {
      title: '更新日期',
      width: 190,
      render: row => h(
        NTime,
        {
          time: (Number(row.update_time) || 0) * 1000
        }
      )
    }
  ];
  if (props.isPage) {
    columns.push({
      title: '操作',
      width: 140,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if (ownUrlPermission('paper/detail')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', tag: 'a', target: '_blank', href: `/full-page/paper-preview?id=${row.id}` }, { default: () => '查看' }));
            }
            if (ownUrlPermission('paper/detail')) {
              tempArr.push(h(
                NButton,
                {
                  text: true,
                  type: 'info',
                  onClick: () => {
                    switch (Number(row.type)) {
                      case composePaperWayEnum.FROM_EXERCISES:
                        router.push(`exercises-edit?id=${ row.id }`);
                        break;
                      case composePaperWayEnum.MANUAL:
                        router.push(`manual-edit?id=${ row.id }`);
                        break;
                    }
                  }
                },
                { default: () => '编辑' }
              ));
            }
            if (ownUrlPermission('paper/delete')) {
              tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => toDel('single', row) }, { default: () => '删除' }));
            }
            return tempArr;
          }
        }
      )  
    });
  }
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  const tableData = ref([]);
  const updateItemCount = count => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = page => {
    pagination.page = page;
    updateTableData();
  };
  const handlePageSizeChange = pageSize => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const excludeExerciseIds = [];
  const getReqParams = () => {
    const {
      page,
      pageSize: page_size
    } = pagination;
    const params = {
      ...searchFormRef.value.getSearchParams(),
      page,
      page_size
    };
    const ids = labelListRef.value.getSelectedIds();
    if (ids.includes('0')) {
      params['Paper[labels_num]'] = 0;
    } else {
      const labelsStr = ids.join(',');
      labelsStr && (params['Paper[labels]'] = labelsStr);
    }
    excludeExerciseIds.length > 0 && (params['Paper[ids]'] = excludeExerciseIds.join(','));
    return params;
  };
  const updateTableData = () => {
    loading.value = true;
    getPaperList(getReqParams()).then(res => {
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateIdPaperMap(res.data.list);
        updateItemCount(res.data.total);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  onMounted(() => {
    if (props.isPage) {
      excludeExerciseIds.splice(0);
      updateTableData();
    }
  });
  const handleSelect = ids => {
    if (props.singleSelect) {
      if (ids.length > 2) {
        return false;
      } else if (ids.length === 2) {
        const index = ids.indexOf(selectedIds.value[0]);
        ids.splice(index, 1);
        selectedIds.value = ids;
      } else {
        selectedIds.value = ids;
      }
    } else {
      selectedIds.value = ids;
    }
  };
  const delReq = ids => {
    loading.value = true;
    batDelPaper({
      'Paper[ids]': ids
    }).then(res => {
      if (res.code === SUCCESS) {
        message.success(ids.length > 1 ? '批量删除成功' : '删除成功');
        handlePageChange(1);
        labelListRef.value.updateLabelList();
        handleSelect([]);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  const toDel = (type, row) => {
    switch (type) {
      case 'single':
        const { id, title } = row;
        dialog.warning({
          title: '删除确认',
          content: `确定删除试卷“${title}”？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            delReq([id]);
          },
          onNegativeClick: () => {}
        });
        break;
      case 'multiple':
        const ids = selectedIds.value;
        dialog.warning({
          title: '删除确认',
          content: `确定删除${ids.length}个试卷？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            delReq(ids);
          },
          onNegativeClick: () => {}
        });
        break;
    }
  };
  const batExportUrl = computed(() => {
    let url = 'javascript:;';
    if (selectedIds.value.length > 0) {
      const params = [];
      params.push(`Authorization=${ getToken() }`);
      params.push(`Paper[ids]=${ selectedIds.value.join(',') }`);
      url = `${ env.apiUrlPrefix }/backend/web/paper/export?${ params.join('&') }`;
    }
    return url;
  });

  const distributeRef = ref(null);
  const toDistribute = () => {
    distributeRef.value.openModal();
  };

  const addBtnOptions = Object.keys(composePaperWayMap).map(key => ({
    label: composePaperWayMap[key],
    key: composePaperWayEnum[key]
  }));
  const handleAdd = key => {
    switch (key) {
      case composePaperWayEnum[composePaperWayEnum.FROM_EXERCISES]:
        router.push('exercises-add');
        break;
      case composePaperWayEnum[composePaperWayEnum.MANUAL]:
        router.push('manual-add');
        break;
    }
  }

  defineExpose({
    getSelectedPapers: () => selectedIds.value.map(id => idPaperMap[id]),
    initData: ({ filterIds = [], initIds = [] } = {}) => {
      excludeExerciseIds.splice(0);
      excludeExerciseIds.push(...filterIds);
      selectedIds.value = [...initIds];
      handlePageChange(1);
    }
  });
</script>