const fileTypeEnum = {
  FILE: 1, // 其他
  VIDEO: 2 // 音频、视频
};

export function getFileTypeFromExt(extWithDot) {
  switch (extWithDot) {
    case '.mp4':
    case '.mp3':
      return fileTypeEnum.VIDEO;
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
    case '.pdf':

    case '.rar':
    case '.zip':
    case '.doc':
    case '.docx':
      return fileTypeEnum.FILE;
  }
}

export default fileTypeEnum;